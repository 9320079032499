import React, { createContext, useContext, useState } from 'react';

// Tworzenie kontekstu
export const RefreshContext = createContext({
  refresh: () => {}
});

// Provider kontekstu
export const RefreshProvider = ({ children }) => {
    const [key, setKey] = useState(0);

    const refresh = () => {
      setKey(prevKey => prevKey + 1); // zmienia klucz, co powoduje rerender
    };

    return (
      <RefreshContext.Provider value={{ refresh, key }}>
        {children}
      </RefreshContext.Provider>
    );
};
