import React, { useState, useEffect, useContext } from 'react';
import './options-menu.styles.scss';
import i18next from '../../services/i18next';
import { useTranslation } from 'react-i18next';

import crossmark from '../../assets/options-menu-assets/crossmark.png';
import leftReturnArrow from '../../assets/options-menu-assets/left-return-arrow.png';
import lightModeIcon from '../../assets/options-menu-assets/sun.png'; // Przykładowe ścieżki do ikon
import darkModeIcon from '../../assets/options-menu-assets/Moon.png';
import brushIcon from '../../assets/options-menu-assets/brush.png';
import classicIconsIcon from '../../assets/options-menu-assets/classic_icons.png';
import experimentalIconsIcon from '../../assets/options-menu-assets/new_icons.png';
import plflag from '../../assets/options-menu-assets/pl_flag.png'; // Ikona dla zakładki "Wybór języka"
import enflag from '../../assets/options-menu-assets/uk_flag.png'; // Ikona dla zakładki "Wybór języka"

import { RefreshContext } from '../../contexts/RefreshContext';

const OptionsMenu = (props) => {

  const {setMenuOpen} = props

  const [currentPage, setCurrentPage] = useState('main');
  const [propsForSubpage, setPropsForSubpage] = useState({});
  const [theme, setTheme] = useState(localStorage.getItem("selectedTheme") || 'light'); // Stan dla trybu jasny/ciemny
  const [icons, setIcons] = useState(localStorage.getItem("selectedIconsMode") || 'classic'); // Stan dla ikon klasyczne/eksperymentalne
  
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'pl'); // Stan dla wyboru języka
  const { t } = useTranslation();

  const { refresh } = useContext(RefreshContext);

  useEffect(() => {
    i18next.changeLanguage(language);
  }, [language]);

  const changeLanguage = (lng) => {
    setLanguage(lng);
    localStorage.setItem('language', lng);
    i18next.changeLanguage(lng);
  };

  const changeTheme = (thm) => {
    setTheme(thm);
    document.body.setAttribute('data-theme', thm);
    localStorage.setItem('selectedTheme', thm);
  }
  const changeIcons = (icn) => {
    setIcons(icn);
    localStorage.setItem('selectedIconsMode', icn);
    refresh();
  }

  const handleTileClick = (page, props = {}) => {
    setPropsForSubpage(props);
    setCurrentPage(page);
  };

  const handleBackClick = () => {
    setCurrentPage('main');
  };

  const handleCloseClick = () => {
    // Logika zamknięcia menu
    setMenuOpen(false)
    // console.log('Zamknięcie menu');
  };

  const renderMainPage = () => (
    <div className="options-menu__content">
      <div className="options-menu__tile" onClick={() => handleTileClick('motywy')}>
        <img src={brushIcon} alt="Ikona motywy" className="options-menu__tile-icon" />
        <span className="options-menu__tile-text">{t(`Motywy`)}</span>
      </div>
      <div className="options-menu__tile" onClick={() => handleTileClick('jezyk')}>
        <img src={language === 'pl' ? plflag : enflag} alt="Ikona języka" className="options-menu__tile-icon" />
        <span className="options-menu__tile-text">{t(`Zmień język`)}</span>
      </div>
    </div>
  );

  const renderMotywyPage = () => (
    <div className="options-menu__subpage">
      <div className="options-menu__toggle-group">
        <button
          className={`options-menu__toggle-button ${theme === 'light' ? 'active' : ''}`}
          onClick={() => changeTheme('light')}
        >
          <img src={lightModeIcon} alt="Tryb jasny" className="options-menu__toggle-icon" />
          
          {t(`Tryb jasny`)}
        </button>
        <button
          className={`options-menu__toggle-button ${theme === 'dark' ? 'active' : ''}`}
          onClick={() => changeTheme('dark')}
        >
          <img src={darkModeIcon} alt="Tryb ciemny" className="options-menu__toggle-icon" />
          {t(`Tryb ciemny`)}
        </button>
      </div>
      <div className="options-menu__toggle-group">
        <button
          className={`options-menu__toggle-button ${icons === 'classic' ? 'active' : ''}`}
          onClick={() => changeIcons('classic')}
        >
          <img src={classicIconsIcon} alt="Ikony klasyczne" className="options-menu__toggle-icon" />
          {t(`Ikony klasyczne`)}
        </button>
        <button
          className={`options-menu__toggle-button ${icons === 'experimental' ? 'active' : ''}`}
          onClick={() => changeIcons('experimental')}
        >
          <img src={experimentalIconsIcon} alt="Ikony eksperymentalne" className="options-menu__toggle-icon" />
          {t(`Nowe ikony`)}
        </button>
      </div>
      <button className="options-menu__back-button" onClick={handleBackClick}>{t(`Powrót`)}</button>
    </div>
  );

  const renderJezykPage = () => (
    <div className="options-menu__subpage">
      <h5>{t("Zaleca się odświeżyć stronę po zmianie języka (F5)")}</h5>
      <div className="options-menu__toggle-group">
        <button
          className={`options-menu__toggle-button ${language === 'pl' ? 'active' : ''}`}
          onClick={() => changeLanguage('pl')}
        >
          <img src={plflag} alt="Polski" className="options-menu__toggle-icon" />
          Polski
        </button>
        <button
          className={`options-menu__toggle-button ${language === 'en' ? 'active' : ''}`}
          onClick={() => changeLanguage('en')}
        >
          <img src={enflag} alt="Angielski" className="options-menu__toggle-icon" />
          English (in progress)
        </button>
      </div>
      <button className="options-menu__back-button" onClick={handleBackClick}>{t(`Powrót`)}</button>
    </div>
  );

  return (
    <div className="options-menu">
      <div className="options-menu__header">
      {currentPage === 'main' ? <></> : <>
      <button className="options-menu__button" onClick={handleBackClick}>
      <img src={leftReturnArrow} alt="leftReturnArrow" className="options-menu__return-icon"/>
      </button>
      </>}
        
        <span className="options-menu__title">{currentPage === 'main' ? t(`Opcje`) : currentPage === 'motywy' ? t(`Motywy`) : t(`Zmień język`)}</span>
        <button className="options-menu__button" onClick={handleCloseClick}>
        <img src={crossmark} alt="crossmark" className="options-menu__return-icon"/>
        </button>
      </div>
      {currentPage === 'main' ? renderMainPage() : currentPage === 'motywy' ? renderMotywyPage() : renderJezykPage()}
    </div>
  );
};

export default OptionsMenu;
