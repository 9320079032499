import { checkForErrorFetch } from "./utility";

/**
 * Websocket. Wysyłanie zapytań i odbieranie danych.
 */
export const getDataFromServer = (message, port) => {
  // console.log(message);
  const messageClear = message.replace(/[;]|[.]/g, "");
  let hasCancelled = false;

  const promise = new Promise((resolve, reject) => {
    if (window[`ws${port}`]) {
      const ws = window[`ws${port}`];
      const checkResponse = (e) => {
        const responseIs = e.data.split(";")[0];
        let responseShouldBe = messageClear;
        switch (messageClear) {
          case "WM":
            responseShouldBe = "WSN";
            break;
          default:
            break;
        }
        if (messageClear[0] === "Q") {
          responseShouldBe = "Q";
        }
        if (messageClear[0] === "I") {
          responseShouldBe = "I";
        }
        if (message.includes("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9")) {
          responseShouldBe = "TOKEN";
        }
        if (messageClear.includes("WD")) {
          responseShouldBe = messageClear.replace("WD", "WV");
        }
        if (messageClear.includes("INFOVWD")) {
          responseShouldBe = "INFOVWD";
        }
        if (messageClear.includes("INFOVWP")) {
          responseShouldBe = "INFOVWP";
        }
        if (messageClear.includes("INFOVWM")) {
          responseShouldBe = "INFOVWM";
        }
        if (responseIs === responseShouldBe) {
          if (hasCancelled) {
            // reject("Error 007"); // - w react v18 powoduje problemy z generowaniem raportow
          } else {
            // if (e.data[0] === "H") {
            //   console.log(e.data);
            // }
            // if (e.data[0] === "Q") {
            //   console.log(e.data.slice(0, 200));
            // }
            // if (e.data[0] === "D") {
            //   console.log(e.data);
            // }
            // if (e.data[0] === "M") {
            //   console.log(e.data);
            // }
            // if (e.data[0] === "S") {
            //   console.log(e.data);
            // }
            // if (e.data[0] === "W") {
            //   console.log(e.data.slice(0, 200));
            // }
            // if (e.data[0] === "A") {
            //   console.log(e.data.slice(0, 1000));
            // }
            // if (e.data[0] === "I") {
            //   console.log(e.data);
            // }
            resolve(e.data);
          }
          ws.removeEventListener("message", checkResponse);
        }
      };
      ws.addEventListener("message", checkResponse);
      // window[`ws${port}`].readyState === 1
      //   ? ws.send(message)
      //   : console.error("Error 020");
        if(window[`ws${port}`].readyState === 1){
          ws.send(message)
        } else{
          console.error("Error 020");
        }

      ws.onerror = () => {
        hasCancelled ? reject("Error 007") : reject("Error 008");
      };
    } else {
      reject("Error 009");
    }
  });

  return {
    promise: promise,
    cancel() {
      hasCancelled = true;
    },
  };
};


export function getMyIPv2() {
  return fetch("/get-ip", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
  .then(checkForErrorFetch)
  .catch((err) => console.error(err));
}

/**
 * Zapytanie do token-server. Uzyskanie tokena Anti-CRSF.
 */
export const getCsrfToken = () => {
  return fetch("/csrf-token", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(checkForErrorFetch)
    .catch((err) => console.error(err));
};

/**
 * Zapytanie do token-server. Logowanie i uzyskanie JWT, listy identyfikatorów obiektów i czasu wygaśnięcia tokena.
 */
export const loginUser = (data) => {
  const { username, password, ip, csrfToken } = data;
  const credentials = { username, password, ip };
  return fetch("/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(credentials),
  })
    .then(checkForErrorFetch)
    .catch((err) => console.error(err));
};

/**
 * Zapytanie do token-server. Sprawdzenie poprawności aktualnego JWT.
 */
export const checkAuth = (csrfToken) => {
  return fetch("/auth", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
    .then(checkForErrorFetch)
    .catch((err) => console.error(err));
};

/**
 * Zapytanie do token-server. Uzyskanie danych obiektu z pliku buildingsConfig.mjs.
 */
export const loadConfig = ({ buildings, csrfToken }) => {
  const bodyObj = { buildings };
  return fetch("/load-config", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(bodyObj),
  })
    .then(checkForErrorFetch)
    .catch((err) => console.error(err));
};

/**
 * Zapytanie do token-server. Uzyskanie ticketa wstawianego jako podprotokół websocketa.
 */
export const getTicket = (csrfToken) => {
  return fetch("/ticket", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
    .then(checkForErrorFetch)
    .catch((err) => console.error(err));
};

/**
 * Zapytanie do token-server. Wygenerowanie nowego tokena JWT.
 */
export const generateNewToken = (data) => {
  const { ip, csrfToken } = data;
  const username = localStorage.getItem("user");
  return fetch("/new-token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({ ip, username }),
  })
    .then(checkForErrorFetch)
    .catch((err) => console.error(err));
};

/**
 * Zapytanie do token-server. Usunięcie cookies (po wylogowaniu).
 */
export const deleteCookie = (csrfToken) => {
  return fetch("/logout", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  })
    .then(checkForErrorFetch)
    .catch((err) => console.error(err));
};
