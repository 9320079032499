// Tworzenie nowego kanału BroadcastChannel
const tokenChannel = new BroadcastChannel('token_channel');

// Funkcja do wysyłania nowego tokenu do innych zakładek
export const broadcastNewToken = (token) => {
    // console.log("Broadcasting new token:", token);
  tokenChannel.postMessage({ type: 'UPDATE_TOKEN', token });
};

// Funkcja do odbierania tokenu
export const onTokenUpdate = (callback) => {
  tokenChannel.onmessage = (event) => {
    // console.log("Received new token:", event.data.token);
    if (event.data.type === 'UPDATE_TOKEN') {
      callback(event.data.token);
    }
  };
};

// Można także dodać funkcję do zamykania kanału, jeśli potrzebujesz
let isChannelClosed = false;

export const closeTokenChannel = () => {
  if (!isChannelClosed) {
    console.log("Closing token channel.");
    tokenChannel.close();
    isChannelClosed = true;
  }
};
