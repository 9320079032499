import { useTranslation } from 'react-i18next';

export const useTranslationHook = () => {
  const { t } = useTranslation();

  const translateText = (text) => {
    if (typeof text === "string") {
      const trailingSpacesCount = text.length - text.trimEnd().length; // Obliczanie liczby spacji na końcu tekstu
      const trimmedText = text.trimEnd();
      const translatedText = t(trimmedText);
      return translatedText + ' '.repeat(trailingSpacesCount); // Dodanie usuniętych spacji z powrotem do przetłumaczonego tekstu
    }
    return text;
  };

  const translateIfString = (element) => {
    if (typeof element === "string") {
      // Sprawdzenie i przetwarzanie stringa wieloliniowego
      if (element.includes("\n")) {
        return element.split("\n").map(line => {
          if(line.includes("Brak informacji")) {
            // console.log(line)
            return t("Brak informacji");
          }
          return translateText(line);
        }).join("\n"); // Ponowne połączenie linii, jeśli potrzebujesz zachować formatowanie
      }
      if(element.includes("Brak informacji")){
        console.log(element)
        return t("Brak informacji");
      }
      return translateText(element);
    } else if (Array.isArray(element)) {
      // Przypadek gdy children to tablica elementów
      return element.map(child => translateIfString(child));
    } else if (element && typeof element === 'object') {
      if (element.props && element.props.children) {
        // Rekurencyjne tłumaczenie children
        return {
          ...element,
          props: { 
            ...element.props,
            children: translateIfString(element.props.children)
          }
        };
      }
    }
    return element;
  };
  
  return translateIfString;
};
