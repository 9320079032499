import React, { useState, useEffect, useRef, Suspense, useCallback } from "react";
import { AnimatePresence, motion} from 'framer-motion'
import { RefreshProvider } from "./contexts/RefreshContext";
import { broadcastNewToken, closeTokenChannel, onTokenUpdate } from './TokenService.js';

import {
  loadConfig,
  generateNewToken,
  checkAuth,
  getDataFromServer,
  deleteCookie,
  getCsrfToken,
  getMyIPv2,
  getToken
} from "./getData";
import { ReactComponent as LoadingIcon } from "./assets/loading_icon.svg";
import { getMyIP } from "./utility";
import Copyright from "./components/copyright/copyright.component";

import "./App.scss";
import UserHelper from "./components/user-helper/user-helper.component";
import DarkMode from "./components/darkmode/darkmode.component";
import IconBar from "./components/icon-bar/icon-bar.component";
import UserHelperWindow from "./components/user-helper/user-helper-window.component";
import M24GuideWizard from "./components/m24-guide/m24-guide-wizard";

import i18next from './services/i18next';// musi byc zaimportowane aby translacje działały
import {useTranslation} from 'react-i18next';
import OptionsMenu from "./components/options-menu/options-menu.component.jsx";
import { useTranslationHook } from "./services/useTranslationHook.js";

import { ReactComponent as InfoIcon } from "./assets/info_icon.svg";
import { ReactComponent as ResetIcon } from "./assets/reset_icon.svg";

/////////////////////////////////////////////////////////////////
//                       Wersja aplikacji                      //
/////////////////////////////////////////////////////////////////
const appVersion = process.env.REACT_APP_VERSION
/////////////////////////////////////////////////////////////////

const Login = React.lazy(() => import("./components/loginv2/login.component"));
const BuildingChooser = React.lazy(() =>
  import("./components/building-chooser/building-chooser.component")
);

const clear = (setToken, buildingsConfig, setIsConfigLoaded, setIsLoggedIn, timeout, getData) => {
  setToken(undefined);
  buildingsConfig.current = {};
  setIsConfigLoaded(false);
  setIsLoggedIn(false);
  localStorage.removeItem("user");
  localStorage.removeItem("buildings");
  localStorage.removeItem("exp");
  clearTimeout(timeout.current);
  for (let i in getData.current) {
    getData.current[i].cancel && getData.current[i].cancel();
  }
  for (let i in window) {
    if (i.includes("ws29")) {
      window[i].close();
      delete window[i];
    }
  }
};

function App() {
  const [token, setToken] = useState();
  const [csrfToken, setCsrfToken] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);

  //// userhelper
  const [windowOpen,setWindowOpen] = useState(false)
  const [menuOpen,setMenuOpen] = useState(false)
  const [currentTipId,setCurrentTipId] = useState("")
  const [helperData,setHelperData] = useState()
  ////////////////////
  
  const buildingsConfig = useRef({});
  const getData = useRef({});
  const timeout = useRef();

  const [wizardVisible, setWizardVisible] = useState(false)
  const [currentHighlightId, setCurrentHighlightId] = useState(null);

  const t = useTranslationHook()

  useEffect(() => {
    const language = localStorage.getItem('language') || 'pl';
    i18next.changeLanguage(language);
  }, []);
  useEffect(() => {
    const theme = localStorage.getItem("selectedTheme") || 'light'
    document.body.setAttribute('data-theme', theme);
  }, []);

  const onCloseWizard = () => {
    // Usuń klasę podświetlenia z aktualnie podświetlonego elementu
    if (currentHighlightId) {
      const element = document.getElementById(currentHighlightId);
      if (element) {
        element.classList.remove('animBorder');
      }
    }

    // Ukryj kreator
    setWizardVisible(false);
  };

  const wizardSteps = [
    { 
      id: 'step1', 
      title: 'Przewodnik aplikacji m24', 
      content: 'Witaj! Przewodnik po aplikacji marani24 nauczy Cię podstaw obsługi. Kliknij "Dalej" by kontynuować lub "X" by wyjść.' 
    },
    { 
      id: 'step2', 
      title: 'Pasek nawigacyjny obiektu', 
      content: 'Pasek nawigacyjny umożliwia poruszanie się między podstronami obiektu i znajduje się na górze ekranu.', 
      highlightId: 'header'
    },
    { 
      id: 'step3', 
      title: 'Przycisk Menu', 
      content: 'W prawym górnym rogu (na komputerze) bądź na górze po środku (na telefonie) znajduje się przycisk Menu.', 
      highlightId: 'header-menu' 
    },
    { 
      id: 'step4', 
      title: 'Funkcje przycisku Menu', 
      content: 'Za pomocą przycisku "Menu" możesz między innymi wylogować się z aplikacji lub zwinąć pasek nawigacyjny, aby zaoszczędzić miejsce na ekranie.', 
      highlightId: 'header-menu-dropdown', 
      action: () => {if(document.querySelector('.header-menu')){document.querySelector('.header-menu').click()}}
    },
    { 
      id: 'step5', 
      title: 'Okno z ciśnieniem', 
      content: 'To okno pokazuje aktualne ciśnienie na wyjściu układu lub inne dane zależne od obiektu.', 
      highlightId: 'outlet-pressure-measurement' 
    },
    { 
      id: 'step6', 
      title: 'Okno statusu', 
      content: 'W oknie statusu pojawiają się ikony: ✔️ oznaczająca normalny stan lub 🔺 w przypadku awarii.', 
      highlightId: 'building-status-icon' 
    },
    { 
      id: 'step7', 
      title: 'Czas odczytu danych', 
      content: 'Czas odczytu informuje o aktualności danych. Jeśli dane nie były aktualizowane przez ponad godzinę, kolor czcionki zmienia się na pomarańczowy.', 
      highlightId: 'measurement-time'
    },
    { 
      id: 'step8', 
      title: 'Podstawowe przyciski', 
      content: 'Omówimy teraz podstawowe przyciski znajdujące się na pasku nawigacyjnym.', 
      highlightId: 'header-navigation-buttons'
    },
    { 
      id: 'step9', 
      title: 'Przycisk "obiekt"', 
      content: 'Przycisk ten prowadzi na główną stronę, gdzie znajdziesz kafelki urządzeń i przydatne parametry.', 
      highlightId: 'header-navigation-buttons-obiekt', 
      action: () => document.getElementById('header-navigation-buttons-obiekt').click()
    },
    { 
      id: 'step10', 
      title: 'Strona "Obiekt"', 
      content: 'Strona dzieli się na różne zakładki, takie jak Sprężarkownia czy wykresy, które prezentują szczegółowe informacje.', 
      highlightId: 'building', 
      action: () => document.getElementById('header-navigation-buttons-obiekt').click()
    },
    {
      id: 'step11',
      title: 'Wykres historyczny',
      content: 'Na górze strony obiektu znajduje się wykres historyczny, który najczęściej przedstawia ciśnienie lub inne kluczowe parametry.',
      highlightId: 'chart-container',
      action: () => document.getElementById('header-navigation-buttons-obiekt').click(),
      hookDir: 'T'
    },
    {
      id: 'step12',
      title: 'Zarządzanie zakładkami',
      content: 'Niektóre zakładki mogą być domyślnie zwinięte. Aby je rozwinąć, kliknij w nagłówek zakładki. Możesz też zwinąć te, które aktualnie nie są potrzebne.',
      highlightId: 'table-component',
      action: () => document.getElementById('header-navigation-buttons-obiekt').click(),
      hookDir: 'T'
    },
    {
      id: 'step13',
      title: 'Przegląd urządzeń',
      content: 'Przewijając stronę w dół, znajdziesz listę urządzeń. Każdą "kafelkę" można kliknąć, aby zobaczyć pełną listę parametrów urządzenia.',
      highlightId: 'building-page-section',
      action: () => document.getElementById('header-navigation-buttons-obiekt').click(),
      hookDir: 'T'
    },
    {
      id: 'step14',
      title: 'Szczegóły urządzenia',
      content: 'Po kliknięciu w kafelkę urządzenia zostaniesz przeniesiony na stronę, która dzieli się na trzy segmenty: kafelkę, wykres oraz tabelę parametrów.',
      highlightId: 'device-page-content',
      action: () => {
        if (document.querySelector('.building-page-link')) {
          document.querySelector('.building-page-link').click()
        }
      },
      hookDir: 'T'
    },
    {
      id: 'step15',
      title: 'Informacje na kafelce',
      content: 'Na kafelce znajdziesz podstawowe informacje o urządzeniu, takie jak status pracy czy kluczowe parametry.',
      highlightId: 'device-item',
      action: () => {
        if (document.querySelector('.building-page-link')) {
          document.querySelector('.building-page-link').click()
        }
      },
      hookDir: 'T'
    },
    {
      id: 'step16',
      title: 'Analiza wykresu',
      content: 'Poniżej kafelki znajduje się wykres przedstawiający najważniejsze dane urządzenia w czasie.',
      highlightId: 'device-chart',
      action: () => {
        if (document.querySelector('.building-page-link')) {
          document.querySelector('.building-page-link').click()
        }
      },
      hookDir: 'T'
    },
    {
      id: 'step17',
      title: 'Tabela parametrów',
      content: 'Tabela zawiera szczegółowe dane o urządzeniu, umożliwiając głębszą analizę jego działania.',
      highlightId: 'device-params-container',
      action: () => {
        if (document.querySelector('.building-page-link')) {
          document.querySelector('.building-page-link').click()
        }
      },
      hookDir: 'T'
    },
    {
      id: 'step18',
      title: 'Monitorowanie alarmów',
      onBack:5,
      content: 'Przycisk "Alarmy" prowadzi na stronę z listą aktywnych alarmów. Ikona przycisku świeci się na czerwono, jeśli występuje alarm.',
      highlightId: 'header-navigation-buttons-alarmy',
      action: () => document.getElementById('header-navigation-buttons-alarmy').click()
    },
    {
      id: 'step19',
      title: 'Zarządzanie alarmami',
      content: 'Na stronie alarmów znajdziesz informacje o każdym aktywnym alarmie.',
      highlightId: 'alerts-page-content',
      action: () => document.getElementById('header-navigation-buttons-alarmy').click()
    },
    {
      id: 'step20',
      title: 'Produkcja',
      content: 'Strona produkcji służy do wyszukiwania danych historycznych w formie tabel oraz wykresów w kilku prostych krokach',
      highlightId: 'header-navigation-buttons-produkcja',
      action: () => document.getElementById('header-navigation-buttons-produkcja').click()
    },
    {
      id: 'step21',
      title: 'Poradnik ekranu produkcji',
      content: 'Jeśli w aplikacji znajdziesz przycisk "?", oznacza to, że znajdziesz tam dodatkowy poradnik. Z powodu obszerności ekranu produkcji wyjaśnienie znajduje się właśnie pod tym przyciskiem.',
      highlightId: 'production-guide-button',
      action: () => document.getElementById('header-navigation-buttons-produkcja').click()
    },
    {
      id: 'step22',
      title: 'Przycisk "Historia"',
      content: 'Po kliknięciu przenosi na stronę poświęconą wykresom, która pozwala na analizę danych historycznych.',
      highlightId: 'header-navigation-buttons-historia',
      action: () => document.getElementById('header-navigation-buttons-historia').click()
    },
    {
      id: 'step23',
      title: 'Analiza historyczna',
      content: 'Na stronie historii znajdują się wykresy z możliwością zmiany zakresu czasu oraz wykresy dotyczące stanów pracy sprężarek, jeśli obiekt je posiada.',
      highlightId: 'header-navigation-buttons-historia',
      action: () => document.getElementById('header-navigation-buttons-historia').click()
    },
    {
      id: 'step24',
      title: 'Zakończenie',
      content: 'Dziękuję za poświęcony czas na przeczytanie krótkiego przewodnika po aplikacji m24. Miłego korzystania!',
      highlightId: 'wizard-close-button'
    }
  ];

  useEffect(() => {
    onTokenUpdate((newToken) => {
      // console.log("Otrzymałem nowy token z innej zakładki:", newToken);
      setToken(newToken);  // Aktualizacja stanu tokenu
      debouncedReconnectAllWebSockets(newToken)
    });

    return () => {
      // console.log("closing broadcast")
      // closeTokenChannel();
    };
  }, []);


  function debounce(func, wait) {
    let timeout;
  
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
  
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }
  
  const debouncedReconnectAllWebSockets = debounce((newToken) => {
    for (let i in window) {
      if (i.includes("ws29")) {
        const port = i.replace("ws", "");
        // console.log("Reconnecting to port:", port, "with new token");
        getData.current[i] = getDataFromServer(newToken + ";" + port, port);
        getData.current[i].promise.then(
          (res) => true,
          (err) => console.error("WebSocket connection failed on port:", port, err)
        );
      }
    }
  }, 1000); // Opóźnienie 1000 milisekund (1 sekunda)
  
  
  

  const checkAuthFunction = () => {
    clearTimeout(timeout.current);
    checkAuth(csrfToken)
      .then((res) => {
        if (res.message === "Authenticated!") {
          const now = Date.now() / 1000;
          const exp = localStorage.exp / 1000;
          if ((exp && exp - 60 < now) || !token) {
            getMyIP()
              .then((res) => {
                const { ip } = res;
                generateNewToken({ ip, csrfToken })
                  .then((response) => {
                    if (response.token) {
                      const t = response.token;
                      const exp = response.expiryDate;
                      broadcastNewToken(t);
                      storeExpiryDate(exp);   
                      setToken(t);
                      
                      for (let i in window) {            
                        if (i.includes("ws29")) {
                          const port = i.replace("ws", "");
                          getData.current[i] = getDataFromServer(
                            t + ";" + port,
                            port
                          );
                          getData.current[i].promise.then(
                            (res) => true,
                            (err) => console.error(err)
                          );
                        }
                      }
                    } else if (response.message) {
                      console.error(response.message);
                    } else if (response.error) {
                      console.error(response.error);
                    }
                  })
                  .catch((error) => console.error("Error 013"));
              })
              .catch((error) => console.error("Error 012"));
          }
          setIsLoggedIn(true);
          if (Object.keys(buildingsConfig.current).length < 1) {
            loadConfig({
              buildings: localStorage.buildings,
              csrfToken: csrfToken,
            })
              .then((data) => {
                buildingsConfig.current = data;
                setIsConfigLoaded(true);
              })
              .catch((err) => console.error("Error 014"));
          }
          timeout.current = setTimeout(checkAuthFunction, 5000);
        } else {
          logout();
        }
      })
      .catch((err) => {
        console.error("Error 015");
        logout();
      });
  };

  useEffect(() => {
    if (csrfToken) {
      checkAuthFunction();
    } else {
      clearTimeout(timeout.current);
      getCsrfToken()
        .then((res) => {
          const { csrfToken } = res;
          setCsrfToken(csrfToken);
        })
        .catch((err) => {
          console.error("Error 016");
          logout();
        });
    }
  });

  const storeBuildings = (buildings) => {
    localStorage.setItem("buildings", buildings);
  };

  const storeUser = (user) => {
    localStorage.setItem("user", user);
  };

  const storeExpiryDate = (exp) => {
    localStorage.setItem("exp", exp);
  };

  const logout = useCallback(() => {
    deleteCookie(csrfToken)
    .then((res) => {
      clear(setToken, buildingsConfig, setIsConfigLoaded, setIsLoggedIn, timeout, getData);
    })
    .catch((err) => {
      console.error("Error 019");
      clear(setToken, buildingsConfig, setIsConfigLoaded, setIsLoggedIn, timeout, getData);
    });
  }, [csrfToken, clear]);

  useEffect(() => {
    const handleServiceWorkerUpdated = (event) => {
      setNewVersionAvailable(true)
    };

    window.addEventListener('serviceWorkerUpdated', handleServiceWorkerUpdated);

    return () => {
      window.removeEventListener('serviceWorkerUpdated', handleServiceWorkerUpdated);
    };
  }, []);

  const refreshPage = () => {
    window.location.reload(true);
  }

  function hardRefresh() {
    logout();
    const currentUrl = window.location.href;
    const separator = currentUrl.includes('?') ? '&' : '?';
    const newUrl = currentUrl + separator + 'nocache=' + new Date().getTime();
    window.location.href = newUrl;
  }

  const updateAlertVariants = {
    hidden:{opacity:0},
    visible:{opacity:1},
    float: {
      y: ["0em", "-0.5em"], // Przesunięcie w górę i w dół
      transition: {
        // repeat: Infinity, // Niekończąca się animacja
        // repeatType: "mirror", // Animacja tam i z powrotem
        duration: 1, // Czas trwania jednego cyklu animacji
        ease: [0.42, 0, 0.58, 1]
      }
    }
}

const [windowSize, setWindowSize] = useState({
  width: window.innerWidth > 1600 ? '55%' :
          window.innerWidth > 1400 ? '60%' :
          window.innerWidth > 1250 ? '70%' :
          '100%',
  height: window.innerWidth < 1250 ? '100%' : '90%',
});


useEffect(() => {
  function handleResize() {
      setWindowSize({
          width: window.innerWidth > 1600 ? '55%' :
                  window.innerWidth > 1400 ? '60%' :
                  window.innerWidth > 1250 ? '70%' :
                  '100%',
          height: window.innerWidth < 1250 ? '100%' : '90%',
      });
  }

  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);

const userhelperwindowvariants = {
  open:{
      width: windowSize.width,
      height: windowSize.height,
      opacity:1,
      transition: {ease:[0.76,0,0.24,1]},
  },
  closed:{
      width: "0%",
      height: "0%",
      opacity:0,
      transition: { ease:[0.76,0,0.24,1]},
  },
  exit:{
      width: "0%",
      height: "0%",
      opacity:0,
      transition: { delay:0.3,ease:[0.76,0,0.24,1]},
  }
}

  // const hardRefresh = () => {
  //   logout();
  //   refreshPage();
  // };

  return (
    <RefreshProvider>
    <div className="app">
      {newVersionAvailable && (
        // Tutaj komponent powiadomienia z przyciskiem do odświeżenia
        <motion.div 
        className="updateAlertBox"
        key={"updateAlertBox"}
        variants={updateAlertVariants}
        initial="hidden"
        animate={["visible","float"]} // Użycie animacji 'float'
        onClick={refreshPage}
        >
          <div id="info-icon-container"><InfoIcon/></div>
          <div id="info-text-container">Nowa wersja aplikacji jest dostępna</div>
        </motion.div>
      )}
      {!isLoggedIn ? (
        <Suspense fallback={<LoadingIcon />}>
          <Login
            csrfToken={csrfToken}
            setToken={setToken}
            storeUser={storeUser}
            storeBuildings={storeBuildings}
            storeExpiryDate={storeExpiryDate}
            clear={logout}
            appVersion={appVersion}
            t={t}
          />
          {/* <Copyright type="copyright-login" /> */}
        </Suspense>
      ) : !isConfigLoaded || !token || !csrfToken ? (
        <>
        <LoadingIcon onClick={logout} />
        <br/>
        <motion.button
          className="refreshButton"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 3 }}
          onClick={hardRefresh}
        >
          {t("Zrestartuj stronę")}
        </motion.button>
        </>
      ) : (
        <Suspense 
        fallback={
          <>
          <LoadingIcon onClick={logout} />
          <br/>
          <motion.button
            className="refreshButton"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 3 }}
            onClick={hardRefresh}
          >
            {t("Zrestartuj stronę")}
          </motion.button>
          </>
       }>
        {wizardVisible && <M24GuideWizard isActive={wizardVisible} steps={wizardSteps} onClose={onCloseWizard} setCurrentHighlightId={setCurrentHighlightId} />}
        {/* <IconBar {...{windowOpen, setWindowOpen, currentTipId, setCurrentTipId, helperData, setHelperData,setMenuOpen,menuOpen}}/> */}
        {/* <AnimatePresence>
        {windowOpen&&(<motion.div
        className='UserHelperWindow'
        variants={userhelperwindowvariants}
        animate={windowOpen? "open" : "closed"}
        initial="closed"
        exit="exit"
        >
        {windowOpen && (<UserHelperWindow currentTipId={currentTipId} setCurrentTipId={setCurrentTipId} windowOpen = {windowOpen}  helperData={helperData} setWindowOpen={setWindowOpen}/>)}
        </motion.div>)}
        </AnimatePresence> */}
        {menuOpen && (
                <OptionsMenu setMenuOpen={setMenuOpen}/>
        )}
          <BuildingChooser
            csrfToken={csrfToken}
            logout={logout}
            buildingsConfig={buildingsConfig.current.buildingsFromServer}
            token={token}
            setWizardVisible = {setWizardVisible}
            setMenuOpen={setMenuOpen}
            t={t}
          />
        </Suspense>
      )}
    </div>
    </RefreshProvider>
  );
}

export default App;
