import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './m24-guide-wizard.styles.scss'
import { useTranslationHook } from '../../services/useTranslationHook';

const M24GuideWizard = ({ isActive, steps, onClose, setCurrentHighlightId}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();

  const t = useTranslationHook()

 
  const [hookPos, setHookPos] = useState({top:'30%'})


  useEffect(() => {
    if (isActive) {
      document.body.style.overflow = 'hidden';
      document.body.scrollIntoView({ behavior: 'smooth', block:"start" });
    } else {
      document.body.style.overflow = 'auto'; // Przywróć domyślne przewijanie
    }

    // Opcjonalnie, czyszczenie w przypadku odmontowania komponentu
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isActive]);




  useEffect(() => {
    // Usuń podświetlenie z poprzednich elementów
    steps.forEach(step => {
      const element = document.getElementById(step.highlightId);
      if (element) {
        element.classList.remove('animBorder');
      }
    });

    if (steps[currentStep].reset) {
      steps[currentStep].reset();
    }

    if (steps[currentStep].action) {
      steps[currentStep].action();
    }
    const currentElement = document.getElementById(steps[currentStep].highlightId);
    if (currentElement) {
      currentElement.classList.add('animBorder');
      setCurrentHighlightId(steps[currentStep].highlightId);
    }

    const highlightCurrentElement = () => {
      const currentElement = document.getElementById(steps[currentStep].highlightId);
      if (currentElement) {
        currentElement.classList.add('animBorder');
        setCurrentHighlightId(steps[currentStep].highlightId);
        clearInterval(intervalId); // Zatrzymaj interval, kiedy element zostanie znaleziony
        
      }
    };

    const intervalId = setInterval(highlightCurrentElement, 50);
  
    return () => {
      clearInterval(intervalId);
    };
  }, [currentStep, steps]);
  

  const goToNextStep = () => {
    setCurrentStep((prevStep) => (prevStep < steps.length - 1 ? prevStep + 1 : prevStep));
  };

  const goToPreviousStep = () => {
    const backSteps = steps[currentStep].onBack || 1;  // Użyj wartości z onBack lub domyślnie cofnij o 1 krok
    setCurrentStep((prevStep) => Math.max(prevStep - backSteps, 0));  // Nie cofnij poniżej kroku 0
    // console.log("Cofnięcie o", backSteps, "kroków");
  };



  useEffect(() => {
    const elementId = steps[currentStep].highlightId;
    const element = document.getElementById(elementId)
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const viewportHeight = window.innerHeight;
      // Cel przewijania taki, żeby górna krawędź elementu była na 25% wysokości viewportu
      const scrollTarget = absoluteElementTop - (viewportHeight * 0.25);
  
      window.scrollTo({
        top: scrollTarget,
        behavior: 'smooth'
      });
    }
    

      const dir = steps[currentStep].hookDir
        // if(dir === "B"){
        //   setHookPos({bottom:'1%'})
        // } else if(dir === "T"){
        //   setHookPos({top:'1%'})
        // } else if(dir === "mid"){
        //   setHookPos({top:'30%'})
        // } else {
        //   setHookPos({top:'30%'})
        // }
        if(dir === "B"){
          setHookPos({top:'99%', transform: "translate(-50%, -100%)"})
        } else if(dir === "T"){
          setHookPos({top:'1%',transform: "translate(-50%, 0%)"})
        } else if(dir === "mid"){
          setHookPos({top:'50%',transform: "translate(-50%, -50%)"})
        } else {
          setHookPos({top:'50%',transform: "translate(-50%, -50%)"})
        }
  }, [currentStep, steps]);




  useEffect(() => {
    // Znajdź element po klasie
    const overlay = document.getElementById('bgblockwizard-container');
    const header = document.getElementById('header');
    if (overlay && header) {
      overlay.style.display = 'block'; 
      header.style.position = 'absolute';
    }

    return () => {
      if (overlay && header) {
        overlay.style.display = 'none'; 
        header.style.position = 'fixed';
      }
    };
  }, []);

  return (
    <>
    {/* style={ hookPos } */}
    <div id='wizard-container' className="wizard-container" style={ hookPos } >
      <div id='wizard-close-button' className="close-button" onClick={onClose}>×</div> {/* Przycisk zamknięcia */}
      <div className="wizard-step" id={steps[currentStep].id}>
        <h2>{t(steps[currentStep].title)}</h2>
        <p>{t(steps[currentStep].content)}</p>
        <button onClick={goToPreviousStep} disabled={currentStep === 0}>{t("Wstecz")}</button>
        <button onClick={goToNextStep} disabled={currentStep === steps.length - 1}>{t("Dalej")}</button>
      </div>
    </div>
    <div className='bgblockwizardcontainer'></div>
    </>
  );
};

export default M24GuideWizard